import Axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'

export const LiquidacionesServices = {
    api: {
        async getAll() {
            return Axios.get('liquidaciones')
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async save(liquidaciones) {
            return Axios.post('liquidaciones', liquidaciones)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async find(id) {
            return Axios.get('liquidaciones/' + id)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async delete(liquidacion) {
            return Axios.delete('liquidaciones/' + liquidacion.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },
    //DATA TABLES
    headers() {
        const header = [{ value: '#', sortable: true }, { value: 'Liquidacion', sortable: true }, { value: 'Recibos', sortable: true }, { value: 'Total', sortable: true }, { value: 'Usuario', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'numero', class: 'text-primary', label: '#' }]
            },
            {
                class: 'important',
                data: [
                    { value: 'tipo_liquidacion', class: 'text-secondary text-small' },
                    { value: 'periodo', class: 'text-bold' },
                ]
            },
            {
                class: 'w-100',
                data: [{ value: 'count_recibos', label: 'Cant. recibos' }]
            },
            {
                class: 'w-100',
                data: [{ value: 'total', label: 'Total liquidado', class: 'text-success', mutator: (value) => { return this.numberFormat(value) } }]
            },
            {
                class: 'w-100',
                data: [{ value: 'user.persona.nombre', label: 'Cargado por' }]
            },
        ]
        return body
    },
    dataList() {
        return [
            { name: "descripcion", class: "" },
            { name: "gerente.nombre", class: "text-secondary" },
        ]
    },
    //FROM EXCEL
    //LIQUIDACION
    findOrNewLiquidacion(fila, liquidaciones) {
        let liquidacion = liquidaciones.find(
            (liquidacion) => liquidacion.numero == parseInt(fila[10])
        );
        if (liquidacion == null || liquidacion == undefined) {
            liquidacion = this.newLiquidacion(fila)
            liquidaciones.push(liquidacion)
        }
        return liquidacion
    },
    newLiquidacion(fila) {
        let liquidacion = {}
        liquidacion.numero = parseInt(fila[10])
        liquidacion.periodo = fila[31].replace('SUELDOS ', '').replace('SUELDO ', '');
        liquidacion.tipo = this.tiposLiquidacion.find(tipo => (tipo.Id == fila[15].substr(0, 1))).Periodo.toUpperCase();
        liquidacion.recibos = []
        return liquidacion
    },
    //RECIBOS
    findOrNewRecibo(fila, recibos, personas) {
        let recibo = recibos.find(
            (recibo) => recibo.numero_legajo == parseInt(fila[0])
        )
        if (recibo == null) {
            recibo = this.newRecibo(fila, personas)
            recibos.push(recibo)
        }
        return recibo
    },
    newRecibo(fila, personas) {
        let recibo = {}
        let persona = personas.find((persona) => {
            if (persona.numero_legajo == fila[0]) {
                return true
            }
            return false
        });
        if (persona) {
            recibo.persona_id = persona.id
            recibo.persona = persona
            recibo.categoria = `${persona.categoria.abreviatura} - ${persona.categoria.descripcion}`
            recibo.gerencia = persona.gerencia.descripcion
            recibo.area = persona.area.descripcion
            recibo.sucursal = persona.sucursal.descripcion
            recibo.obra_social = `${persona.obra_social.codem} ${persona.obra_social.descripcion}`
            recibo.sueldo_basico = `${persona.categoria.total}`
        } else {
            throw `El legajo ${fila[0]} no se encuentra en la base de datos`
                // recibo.persona_id = 0
                // recibo.persona = null
                // recibo.categoria = '-'
                // recibo.gerencia = '-'
                // recibo.area = '-'
                // recibo.sucursal = '-'
                // recibo.obra_social = '-'
        }
        recibo.numero_legajo = parseInt(fila[0])
        recibo.nombre = fila[33]
        recibo.cuil = fila[34]
        recibo.fecha_ingreso = moment(fila[35], 'DD-MMM-YY').format('YYYY-MM-DD')
        recibo.sector = null
        recibo.funcion = null
        recibo.lugar_pago = fila[13]
        recibo.per_dep = moment(fila[17], 'MMYYYY').format('MM/YYYY')
        recibo.fecha_dep = moment(fila[16], 'DDMM').format('DD/MM')
        recibo.periodo_pago = moment(fila[15].substr(1), 'DDYYYY').format('DD/YYYY')
        recibo.fecha_pago = moment(fila[14], 'DDMMYYYY').format('YYYY-MM-DD')
        recibo.banco = fila[18]
        recibo.haberes_remunerativos = 0
        recibo.haberes_no_remunerativos = 0
        recibo.deducciones = 0
        recibo.neto = 0
        recibo.conceptos = []
        return recibo
    },
    //CONCEPTOS
    findOrNewConcepto(fila, recibo) {
        let concepto = recibo.conceptos.find((concepto) => concepto.codigo == parseInt(fila[2]))
        if (concepto == null) {
            concepto = {}
            concepto.codigo = parseInt(fila[2])
            concepto.concepto = fila[3]
            concepto.cantidad = (fila[5] == 0) ? fila[4] : fila[5]
            concepto.tipo = this.tipoConcepto(fila[32])
            concepto.importe = (concepto.tipo == 'deduccion') ? fila[6] * -1 : fila[6]
            this.calcularHaberes(recibo, concepto)
            recibo.conceptos.push(concepto)
        }
    },
    tipoConcepto(col) {
        if (col == 1)
            return 'remunerativo'
        if (col == 2)
            return 'deduccion'
        if (col == 3)
            return 'no_remunerativo'
        return ''
    },
    calcularHaberes(recibo, concepto) {
        switch (concepto.tipo) {
            case 'remunerativo':
                recibo.haberes_remunerativos += concepto.importe
                recibo.neto += concepto.importe
                break
            case 'no_remunerativo':
                recibo.haberes_no_remunerativos += concepto.importe
                recibo.neto += concepto.importe
                break
            case 'deduccion':
                recibo.deducciones += concepto.importe
                recibo.neto -= concepto.importe
                break
            default:
                break
        }
    },
    numberFormat(val) {
        const options2 = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options2);
        return numberFormat.format(val)
    },
    tiposLiquidacion: [{
            "Id": "0",
            "Periodo": "Mensual"
        },
        {
            "Id": "1",
            "Periodo": "Primera Quincena"
        },
        {
            "Id": "2",
            "Periodo": "Segunda Quincena"
        },
        {
            "Id": "3",
            "Periodo": "Aguinaldo"
        },
        {
            "Id": "4",
            "Periodo": "Vacaciones"
        },
        {
            "Id": "5",
            "Periodo": "Adelanto"
        },
        {
            "Id": "8",
            "Periodo": "Calculo auxiliar base fija vacaciones"
        },
        {
            "Id": "9",
            "Periodo": "Liquidacion final"
        }
    ]
}